var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12 col-xl-10"},[_c('v-sheet',{staticClass:"dense-inputs"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex flex-row float-right",attrs:{"cols":"12","lg":"12","md":"12","xs":"12"}},[_c('v-text-field',{staticClass:"mt-2 mb-0 force-text-left",attrs:{"label":_vm.$t('message.filterResults'),"value":_vm.searchTerm,"autocomplete":"off","prepend-inner-icon":"mdi-filter-outline"},on:{"change":function($event){_vm.searchTerm = $event}}}),_c('v-btn',{staticClass:"ml-3",attrs:{"loading":_vm.loading.filterResults},on:{"click":_vm.filterResults}},[_vm._v(_vm._s(_vm.$t('message.filter')))]),_c('v-btn',{staticClass:"ml-3",on:{"click":function($event){_vm.searchTerm = null}}},[_c('v-icon',[_vm._v("refresh")])],1),_c('ExportTable',{staticClass:"mt-0 mr-0",staticStyle:{"padding-top":"2px !important"},attrs:{"has-add-function":true,"cols-to-delete":_vm.colsToDelete,"file-name":"payment-terms","table-id":"paymentTermsTable"},on:{"add-action":function($event){return _vm.openCreatePaymentTermDialog()}}}),_c('HelpPopUpV2',{attrs:{"help-page-component":"PaymentTermsListing","no-padding":true}})],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.loading.paymentTerms,"absolute":"","opacity":"0.15"}},[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"40","width":"6"}})],1)],1)],1),_c('v-data-table',{staticClass:"mt-0 appic-table-light specification-table",attrs:{"footer-props":{
            itemsPerPageOptions: [20,50,-1],
            showCurrentPage: true,
            showFirstLastPage: true
        },"headers":_vm.headers,"height":_vm.tableHeight,"items":_vm.allPaymentTerms,"items-per-page":-1,"options":_vm.tableOptions,"search":_vm.searchTerm,"calculate-widths":"","dense":"","fixed-header":"","id":"paymentTermsTable","item-key":"Paymentterm.id"},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.allPaymentTerms.indexOf(item) + 1))])]}},{key:"item.Paymentterm.title",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.Paymentterm.title))])]}},{key:"item.Paymentterm.paymentterm_id",fn:function(ref){
        var item = ref.item;
return [(item.Paymentterm.paymentterm_id != null)?_c('span',[_vm._v(_vm._s(_vm.getOtsTerm(item.Paymentterm.paymentterm_id)))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.$t('message.pleaseProvide')))])]}},{key:"item.Paymentterm.dppaid_id",fn:function(ref){
        var item = ref.item;
return [(item.Paymentterm.dprequired == 1 && item.Paymentterm.dppaid_id != null)?_c('span',[_vm._v(_vm._s(_vm.getOtsTerm(item.Paymentterm.dppaid_id)))]):_vm._e(),(item.Paymentterm.dprequired == 1 && item.Paymentterm.dppaid_id == null)?_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.$t('message.pleaseProvide')))]):_vm._e()]}},{key:"item.Paymentterm.fullypaid_id",fn:function(ref){
        var item = ref.item;
return [(item.Paymentterm.fullypaid_id != null)?_c('span',[_vm._v(_vm._s(_vm.getOtsTerm(item.Paymentterm.fullypaid_id)))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.$t('message.pleaseProvide')))])]}},{key:"item.Paymentterm.model",fn:function(ref){
        var item = ref.item;
return [(item.Paymentterm.model == 'c')?_c('span',[_vm._v(_vm._s(_vm.$t('message.buyer')))]):_vm._e(),(item.Paymentterm.model == 'p')?_c('span',[_vm._v(_vm._s(_vm.$t('message.supplier')))]):_vm._e()]}},{key:"item.Paymentterm.non_standard",fn:function(ref){
        var item = ref.item;
return [(item.Paymentterm.non_standard == 0 || item.Paymentterm.non_standard == null)?_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.$t('message.standard')))]):_vm._e(),(item.Paymentterm.non_standard == 1)?_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.$t('message.nonStandard')))]):_vm._e()]}},{key:"item.Paymentterm.grossmargineffect_id",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getGrossMarginEffect(item.Paymentterm.grossmargineffect_id)))])]}},{key:"item.Paymentterm.localsales_only",fn:function(ref){
        var item = ref.item;
return [(item.Paymentterm.localsales_only == 1)?_c('span',[_vm._v(_vm._s(_vm.$t('message.yes')))]):_c('span',[_vm._v(_vm._s(_vm.$t('message.no')))])]}},{key:"item.Paymentterm.id",fn:function(ref){
        var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"icon":""}},Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.moreActions')))])])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{staticClass:"font-sm",on:{"click":function($event){return _vm.openEditPaymentTermDialog(item.Paymentterm.id, item.Paymentterm.title)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"black lighten-1","small":""}},[_vm._v("edit")]),_vm._v(_vm._s(_vm.$t('message.updatePaymentTerm'))+" ")],1),_c('v-list-item',{staticClass:"font-sm",on:{"click":function($event){return _vm.deletePaymentTerm(item.Paymentterm.id, item.Paymentterm.title)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"red lighten-1"}},[_vm._v("cancel")]),_vm._v(_vm._s(_vm.$t('message.deletePaymentTerm')))],1)],1)],1)]}}])}),_c('AddPaymentTerm',{attrs:{"payment-term-id":_vm.selectedPaymentTermId,"payment-term-name":_vm.selectedPaymentTermName,"dialog":_vm.editDialog,"update-mode":_vm.updateMode},on:{"update:dialog":function($event){_vm.editDialog=$event},"dialog-closed":_vm.editDialogClosed,"update-done":_vm.paymentTermUpdated,"create-done":_vm.paymentTermCreated}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
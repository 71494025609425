<template>
    <div class="col-12 col-xl-10">
        <v-sheet class="dense-inputs">
            <v-row no-gutters>
                <v-col cols="12" lg="12" md="12" xs="12" class="d-flex flex-row float-right">
                    <v-text-field
                        :label="$t('message.filterResults')"
                        :value="searchTerm"
                        autocomplete="off"
                        class="mt-2 mb-0 force-text-left"
                        prepend-inner-icon="mdi-filter-outline"
                        @change="searchTerm = $event"
                    ></v-text-field>
                    <v-btn class="ml-3" :loading="loading.filterResults" @click="filterResults">{{ $t('message.filter') }}</v-btn>
                    <v-btn class="ml-3" @click="searchTerm = null"><v-icon>refresh</v-icon></v-btn>
                    <ExportTable
                        :has-add-function="true"
                        :cols-to-delete="colsToDelete"
                        class="mt-0 mr-0"
                        file-name="payment-terms"
                        table-id="paymentTermsTable"
                        style="padding-top: 2px !important;"
                        @add-action="openCreatePaymentTermDialog()"
                    ></ExportTable>
                    <HelpPopUpV2 help-page-component="PaymentTermsListing" :no-padding="true"></HelpPopUpV2>
                </v-col>
            </v-row>
        </v-sheet>
        <v-overlay
            :value="loading.paymentTerms"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: [20,50,-1],
                showCurrentPage: true,
                showFirstLastPage: true
            }"
            :headers="headers"
            :height="tableHeight"
            :items="allPaymentTerms"
            :items-per-page="-1"
            :options.sync="tableOptions"
            :search="searchTerm"
            calculate-widths
            class="mt-0 appic-table-light specification-table"
            dense
            fixed-header
            id="paymentTermsTable"
            item-key="Paymentterm.id"
        >
            <template v-slot:item.index="{ item }">
                <span class="text-center">{{ allPaymentTerms.indexOf(item) + 1}}</span>
            </template>
            <template v-slot:item.Paymentterm.title="{ item }">
                <span class="font-weight-bold">{{ item.Paymentterm.title }}</span>
            </template>
            <template v-slot:item.Paymentterm.paymentterm_id="{ item }">
                <span v-if="item.Paymentterm.paymentterm_id != null">{{ getOtsTerm(item.Paymentterm.paymentterm_id) }}</span>
                <span v-else class="red--text">{{ $t('message.pleaseProvide') }}</span>
            </template>
            <template v-slot:item.Paymentterm.dppaid_id="{ item }">
                <span v-if="item.Paymentterm.dprequired == 1 && item.Paymentterm.dppaid_id != null">{{ getOtsTerm(item.Paymentterm.dppaid_id) }}</span>
                <span v-if="item.Paymentterm.dprequired == 1 && item.Paymentterm.dppaid_id == null" class="red--text">{{ $t('message.pleaseProvide') }}</span>
            </template>
            <template v-slot:item.Paymentterm.fullypaid_id="{ item }">
                <span v-if="item.Paymentterm.fullypaid_id != null">{{ getOtsTerm(item.Paymentterm.fullypaid_id) }}</span>
                <span v-else class="red--text">{{ $t('message.pleaseProvide') }}</span>
            </template>
            <template v-slot:item.Paymentterm.model="{ item }">
                <span v-if="item.Paymentterm.model == 'c'">{{ $t('message.buyer') }}</span>
                <span v-if="item.Paymentterm.model == 'p'">{{ $t('message.supplier') }}</span>
            </template>
            <template v-slot:item.Paymentterm.non_standard="{ item }">
                <span class="text-no-wrap" v-if="item.Paymentterm.non_standard == 0 || item.Paymentterm.non_standard == null">{{ $t('message.standard') }}</span>
                <span class="text-no-wrap" v-if="item.Paymentterm.non_standard == 1">{{ $t('message.nonStandard') }}</span>
            </template>
            <template v-slot:item.Paymentterm.grossmargineffect_id="{ item }">
                <span>{{ getGrossMarginEffect(item.Paymentterm.grossmargineffect_id) }}</span>
            </template>
            <template v-slot:item.Paymentterm.localsales_only="{ item }">
                <span v-if="item.Paymentterm.localsales_only == 1">{{ $t('message.yes') }}</span>
                <span v-else>{{ $t('message.no') }}</span>
            </template>
            <template v-slot:item.Paymentterm.id="{ item }">
                <v-menu>
                    <template v-slot:activator="{ on: menu }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                    <v-icon>more_vert</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('message.moreActions') }}</span>
                        </v-tooltip>
                    </template>
                    <v-list dense>
                        <v-list-item class="font-sm" @click="openEditPaymentTermDialog(item.Paymentterm.id, item.Paymentterm.title)">
                            <v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updatePaymentTerm') }}
                        </v-list-item>
                        <v-list-item class="font-sm" @click="deletePaymentTerm(item.Paymentterm.id, item.Paymentterm.title)"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.deletePaymentTerm') }}</v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>
        <AddPaymentTerm
            :payment-term-id="selectedPaymentTermId"
            :payment-term-name="selectedPaymentTermName"
            :dialog.sync="editDialog"
            :update-mode="updateMode"
            @dialog-closed="editDialogClosed"
            @update-done="paymentTermUpdated"
            @create-done="paymentTermCreated"
        ></AddPaymentTerm>
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {mapActions, mapGetters} from "vuex";
// import {api} from "Api";
// import {grossMarginEffects} from "@/store/modules/appic/constants";
const AddPaymentTerm = () => import("Components/Appic/AddPaymentTerm");
const ExportTable = () => import("Components/Appic/ExportTable");
const HelpPopUpV2 = () => import("Components/Appic/Help/HelpPopUpV2");

export default {
    name: "PaymentTermsListing",
    components: { AddPaymentTerm, ExportTable, HelpPopUpV2 },
    props: ['addDialog','reload'],
    data() {
        return {
            allPaymentTerms: [],
            colsToDelete: {
                start_col: 0,
                ncols: 1
            },
            dialogs: {
                error: false,
                error_message: ""
            },
            editDialog: null,
            filterOptions: {
                office: 1
            },
            hideDefaultFooter: true,
            loading: {
                fetch: false,
                filterResults: false,
                paymentTerms: false
            },
            paymentStatuses: [],
            searchField: null,
            searchTerm: null,
            selectedPaymentTermId: null,
            selectedPaymentTermName: null,
            sortBy: ['Paymentterm.model' ,'Paymentterm.title'],
            tableHeight: '500',
            tableOptions: {
                page: 1
            },
            totalPaymentTerms: 0,
            updateMode: false
        }
    },
    computed: {
        ...mapFields('paymentterm',{
            allBuyerPaymentTerms: 'allBuyerPaymentTerms',
            allSupplierPaymentTerms: 'allSupplierPaymentTerms'
        }),
        ...mapGetters([
            'grossMarginEffects'
        ]),
        headers () {
            return [
                // {
                //     id: 0,
                //     text: this.$t('message.no'),
                //     value: 'index',
                //     class: 'light-green lighten-3 pa-1 width-1-pct',
                //     sortable: false
                // },
                {
                    id: 1,
                    text: this.$t('message.actions'),
                    value: 'Paymentterm.id',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: false
                },
                {
                    id: 2,
                    text: this.$t('message.for'),
                    value: 'Paymentterm.model',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 3,
                    text: this.$t('message.term'),
                    value: 'Paymentterm.title',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 4,
                    text: this.$t('message.otsTerm'),
                    value: 'Paymentterm.paymentterm_id',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 5,
                    text: this.$t('message.otsDpPaidTerm'),
                    value: 'Paymentterm.dppaid_id',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 5,
                    text: this.$t('message.otsFullyPaidTerm'),
                    value: 'Paymentterm.fullypaid_id',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 7,
                    text: this.$t('message.type'),
                    value: 'Paymentterm.non_standard',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 5,
                    text: this.$t('message.grossMarginEffect'),
                    value: 'Paymentterm.grossmargineffect_id',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 6,
                    text: this.$t('message.whSalesOnly'),
                    value: 'Paymentterm.localsales_only',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
            ]
        }
    },
    methods: {
        ...mapActions('paymentterm', {
            deletePaymentTermById: 'deletePaymentTermById',
            getAllPaymentTerms: 'getAllPaymentTerms',
            getPaymentStatuses: 'getPaymentStatuses',
            resetState: 'resetState'
        }),
        async deletePaymentTerm(val, title) {
            if(await this.$root.$confirm(this.$t('message.deletePaymentTerm') + ' ' +  title, this.$t('message.confirmations.continuePaymentTermCancelAction'), {color: 'orange'})){
                this.deletePaymentTermById(val).then((status) => {
                    if(status == 'done'){
                        this.$toast.success(this.$t('message.successes.paymentTermDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'check_circle_outline'
                            }
                        )
                        this.allPaymentTerms = []
                        this.resetState()
                        this.loadAllPaymentTerms()
                    } else {
                        this.$toast.error(this.$t('message.errors.paymentTermNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }
                })
            }
        },
        editDialogClosed () {
            this.editDialog = false;
            if(this.updateMode == false){
                this.$emit('add-dialog-closed')
            }
        },
        filterResults () {
            this.loading.filterResults = true
            let _this = this
            setTimeout(function(){
                _this.loading.filterResults = false
            },700)
        },
        getGrossMarginEffect (val) {
            let effect = this.grossMarginEffects.find(e => e.value == val)
            if(effect){
                return effect.title
            }
            return this.$t('message.na')
        },
        getOtsTerm(paymentStatusId){
            let paymentStatus = this.paymentStatuses.find(s => s.PaymentStatus.id == paymentStatusId)
            if(paymentStatus){
                return paymentStatus.PaymentStatus.title
            }
            return ''
        },
        handleResize() {
            this.tableHeight = window.innerHeight - (285);
        },
        loadAllPaymentTerms () {
            this.loading.paymentTerms = true
            this.getAllPaymentTerms().then(() => {
                this.allBuyerPaymentTerms.forEach(paymentTerm => {
                    this.allPaymentTerms.push(paymentTerm)
                })
                this.allSupplierPaymentTerms.forEach(paymentTerm => {
                    this.allPaymentTerms.push(paymentTerm)
                })
                this.loading.paymentTerms = false
            })
        },
        openCreatePaymentTermDialog () {
            this.updateMode = false
            this.editDialog = true
        },
        openEditPaymentTermDialog (paymentTermId, paymentTermName) {
            this.selectedPaymentTermId = paymentTermId
            this.selectedPaymentTermName = paymentTermName
            this.updateMode = true
            this.editDialog = true
        },
        paymentTermCreated () {
            this.editDialog = false
            this.allPaymentTerms = []
            this.resetState()
            this.loadAllPaymentTerms()
            this.$emit('reload')
        },
        paymentTermUpdated () {
            this.editDialog = false
            this.allPaymentTerms = []
            this.resetState()
            this.loadAllPaymentTerms()
            this.$emit('reload')
        },
    },
    watch: {
        addDialog(value) {
            if(value) {
                this.openCreatePaymentTermDialog ()
            }
        }
    },
    created() {
        this.allPaymentTerms = []
        this.resetState()
        this.loadAllPaymentTerms()
        window.addEventListener('resize', this.handleResize)
    },
    updated() {
        this.getPaymentStatuses()
            .then( data => {
                this.paymentStatuses = data
            })
    },
    mounted() {
        this.handleResize()
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style>
    .font-xs {
        font-size: 0.65rem;
    }
    .mt-5px {
        margin-top: 5px !important;
    }
    .mt-6px {
        margin-top: 6px !important;
    }
    .text-start {
        padding-left: 3px !important;
        padding-right: 3px !important;
        vertical-align: middle !important;
    }
    .truncate {
        display: inline-block;
        width: 100px !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .v-data-table td {
        padding: 0 3px;
        font-size: 0.8rem !important;
        line-height: 0.8rem;
        vertical-align: center;
    }
    .v-data-table-header th {
        white-space: nowrap;
    }
</style>